<template>
  <Button variant="outline" class="filter-button">
    <slot></slot>
  </Button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.filter-button {
  border-radius: 12px;
  font-weight: normal !important;
  padding-inline: 12px !important;
  border: 1px solid var(--gray-6) !important;
  box-shadow: var(--shadow-0);

  &:hover {
    border: 1px solid var(--gray-5) !important;
  }
}
</style>
