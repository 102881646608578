<template>
  <FilterIcon :icon="mapIcon.remove_circle" class="FilterClear" />
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.FilterClear {
  font-size: var(--size-14);

  &:hover {
    color: var(--gray-2);
  }
}
</style>
